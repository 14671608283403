@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "inter";
    src: local("inter"), url(./fonts/inter.ttf) format("truetype");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
    font-family: "inter", sans-serif;
    text-decoration-line: none;
        text-decoration: none;
        padding: 0;
        margin: 0;
        overflow-x: hidden;
}

a {
    text-decoration-line: none;
    text-decoration: none;
    /* color: white; */
}

.doelgroep a{
    color: white;
}

.blacktext h5 {
    color: gray;
}

html {
    padding: 0;
    margin: 0;
}

.interfont{
    font-family: "inter", sans-serif;
}