.ais-SearchBox-input {
  border-style: none;
  box-shadow: none;
  background-color: rgba(255, 222, 173, 0);
}

ais-instantSearch {
  background-color: aquamarine;
}

.ais-Hits-list {
  background-color: white;
  padding-left: 1.5rem;
  max-height: calc(100vh - 90px);
  display: block !important;
  border-radius: 0 0 10px 10px;
  overflow: scroll;
}

.ais-Snippet-highlighted {
  background-color: rgba(0, 187, 255, 0.61);
}

.ais-Hits-item {
  border-bottom: 1px rgba(177, 175, 175, 0.632) solid;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.ais-SearchBox-resetIcon {
  width: 32px;
}

.ais-SearchBox-submitIcon {
  visibility: hidden;
}

.ais-SearchBox-submit {
  visibility: hidden;
}

.ais-SearchBox {
  height: 3rem;
  background-color: white;
  border-radius: 10px 10px 0 0;
  padding: 0.25rem;
  border-bottom: 1px rgb(177, 175, 175) solid;
}

.ais-SearchBox-input {
  height: 2.5rem;
  width: 100%;
  padding: 0.75rem;
  pointer-events: auto;
}

.ais-SearchBox-input:focus {
  outline: none;
  pointer-events: auto;
}

.ais-SearchBox-input::-ms-clear {
  display: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.ais-SearchBox-submit {
  display: none;
}

.ais-SearchBox-form {
  display: flex;
}

.wysig_list_icon {
  color: blue;
  background-color: blue;
  border-style: double;
}
