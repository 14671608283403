.ais-Hits-list {
  width: 100%;
  display: grid;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* display: flex !important; */
  flex-direction: row;
  flex-wrap: wrap;
}

.imgHit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}

.ais-Hits-item {
  width: 200px;
}
